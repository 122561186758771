@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

* {
  scroll-behavior: smooth !important;
}

body {
  background-color: #1a1a1a;
  color: #ffffff;
}

@font-face {
  font-family: "nastaliq";
  src: url("../fonts/NotoNastaliqUrdu.ttf");
}

@font-face {
  font-family: "vazir";
  src: url("../fonts/VazirmatnRegular.ttf");
}
